import {
  Textarea,
  Button,
  VStack,
  useToast,
  Text,
  HStack,
  Divider
} from '@chakra-ui/react'
import ResizeTextarea from 'react-textarea-autosize'
import { useState, FC, useEffect, useRef } from 'react'
import Label from 'components/Label'
import VoiceSelect from 'pages/settings/VoiceSelect'
import { Unsubscribe } from 'firebase/firestore'
import { DBT } from 'types/internal'
import _ from 'lodash'
import TemperatureInput from 'components/TemperatureInput'
import JsonSchemeInput from 'components/JsonSchemeInput'
import {
  dbSaveVegasConfTemplate,
  dbSubscribeToConf,
  dbSubscribeToVegasTemplates,
  dbUpdateConf
} from 'controllers/vegas'
import CannedResponse from 'pages/settings/CannedResponse'
import InterruptionMode from 'pages/settings/InterruptionMode'
import NavBar from 'components/NavBar'
import EditableTranslates from 'components/EditableTranslates'
import TranscriberSelect from 'components/TranscriberSelect'
import CreateTemplateButton from 'components/CreateTemplateButton'
import dayjs from 'dayjs'
import VegasTemplates from 'pages/demo2/Demo2Templates'
import AiPlatformSelect from 'pages/settings/AIPlatformSelect'
import AIModels from 'pages/settings/AIModels'
import SubPrompt from 'pages/demo2/SubPrompt'

type Props = {}

const VegasDemo: FC<Props> = () => {
  const [conf, setConf] = useState<DBT.AIPromptConfT | null>(null)
  const [savedConf, setSavedConf] = useState<DBT.AIPromptConfT | null>(null)
  const unsubscribeRef = useRef<Unsubscribe | null>(null)
  const toast = useToast()
  const unsubscribeTemplatesRef = useRef<Unsubscribe | null>(null)
  const [templates, setTemplates] = useState<DBT.AIConfTemplateT[]>([])

  useEffect(() => {
    if (unsubscribeRef.current) {
      unsubscribeRef.current()
    }
    if (unsubscribeTemplatesRef.current) {
      unsubscribeTemplatesRef.current()
    }
    unsubscribeRef.current = dbSubscribeToConf(conf => {
      setConf(conf)
      setSavedConf(conf)
    })
    unsubscribeTemplatesRef.current = dbSubscribeToVegasTemplates(templates =>
      setTemplates(templates)
    )
    return () => {
      if (unsubscribeRef.current) {
        unsubscribeRef.current()
      }
      if (unsubscribeTemplatesRef.current) {
        unsubscribeTemplatesRef.current()
      }
    }
  }, [])

  const getConf = () => {
    if (!conf) {
      return null
    }
    try {
      const newConf = { ...conf }
      if (newConf.jsonScheme) {
        JSON.parse(newConf.jsonScheme)
      }
      return newConf
    } catch (e) {
      toast({
        title: 'Error',
        description: 'Failed to save configuration, check JSON scheme',
        status: 'error',
        duration: 3000,
        isClosable: true
      })
      return null
    }
  }

  const onSave = () => {
    const c = getConf()
    if (c) {
      dbUpdateConf(c)
    }
  }

  const onVoiceChange = (voiceId: string) => {
    console.log('onVoiceChange', voiceId)
    if (conf) {
      setConf({ ...conf, voiceId })
    }
  }

  const onChangePrompt = (prompt: string) => {
    if (conf) {
      setConf({ ...conf, prompt })
    }
  }

  const onChangeInitialPhrase = (initialPhrase: string) => {
    if (conf) {
      setConf({ ...conf, initialPhrase })
    }
  }

  const onChangeFinalPhrase = (finalPhrase: string) => {
    if (conf) {
      setConf({ ...conf, finalPhrase })
    }
  }

  const onJsonSchemeChange = (jsonScheme: string) => {
    if (conf) {
      setConf({ ...conf, jsonScheme })
    }
  }

  const onChangeCannedResponse = (cr: Record<string, string>) => {
    if (conf) {
      setConf({ ...conf, cannedResponse: cr })
    }
  }

  const onChangeInterruptionMode = (mode: DBT.INTERRUPT) => {
    if (conf) {
      setConf({ ...conf, interruptionMode: mode })
    }
  }

  const onChangeAiPlatform = (p: DBT.AIPlatform) => {
    if (conf) {
      setConf({ ...conf, aiPlatform: p })
    }
  }

  const onChangeModels = (models: Record<DBT.AIPlatform, string>) => {
    if (conf) {
      setConf({ ...conf, models })
    }
  }

  const onChageTranscriber = (v: DBT.TRANSCRIBER) => {
    if (conf) {
      setConf({ ...conf, transcriber: v })
    }
  }

  const onChange = (params: Partial<DBT.AIPromptConfT>) => {
    if (conf) {
      setConf({ ...conf, ...params })
    }
  }

  const onSubPromptChange = (sp: DBT.SubPromptT, state: DBT.Demo2Stage) => {
    if (conf) {
      const prompts = conf.prompts || {}
      setConf({
        ...conf,
        prompts: {
          ...prompts,
          [state]: sp
        }
      })
    }
  }

  const renderTemperatureInput = () => {
    if (!conf) return null
    const value = conf.temperature || 0
    return (
      <TemperatureInput
        value={value}
        onChange={value => setConf({ ...conf, temperature: value })}
      />
    )
  }

  const onCreateTemplate = async (name: string) => {
    console.log('onCreateTemplate', name)
    const c = getConf()
    if (c) {
      name = name || dayjs().format('ll')
      await dbSaveVegasConfTemplate(name, c)
    }
  }

  const loadTemplate = (t: DBT.AIConfTemplateT) => {
    setConf(t.conf)
  }

  const actions = (
    <HStack spacing={4}>
      <VegasTemplates items={templates} onItemClick={loadTemplate} />
      <CreateTemplateButton onCreate={onCreateTemplate} />
      <Button
        variant={'outline'}
        colorScheme='white'
        onClick={onSave}
        isDisabled={_.isEqual(conf, savedConf)}
        size='sm'
      >
        Save
      </Button>
    </HStack>
  )

  const renderInitialPhrase = () => {
    return (
      <VStack w='full' align={'flex-start'} spacing={2}>
        <Label>Initial phrase</Label>
        <Textarea
          placeholder='Enter initial phrase here'
          value={_.get(conf, 'initialPhrase', '')}
          onChange={e => onChangeInitialPhrase(e.target.value)}
          fontSize={'sm'}
          minH='unset'
          overflow='hidden'
          w='100%'
          resize='none'
          minRows={2}
          as={ResizeTextarea}
        />
        <EditableTranslates
          phrase={_.get(conf, 'initialPhrase', '')}
          value={conf?.initialPhraseTranslates || {}}
          onChange={t => onChange({ initialPhraseTranslates: t })}
        />
      </VStack>
    )
  }

  const renderFinalPhrase = () => {
    return (
      <VStack w='full' align={'flex-start'} spacing={2}>
        <Label>Final phrase</Label>
        <Textarea
          placeholder='Enter final phrase here'
          value={_.get(conf, 'finalPhrase', '')}
          onChange={e => onChangeFinalPhrase(e.target.value)}
          fontSize={'sm'}
          minH='unset'
          overflow='hidden'
          w='100%'
          resize='none'
          minRows={2}
          as={ResizeTextarea}
        />
        <EditableTranslates
          phrase={_.get(conf, 'finalPhrase', '')}
          value={conf?.finalPhraseTranslates || {}}
          onChange={t => onChange({ finalPhraseTranslates: t })}
        />
      </VStack>
    )
  }

  const renderPrompts = () => {
    return (
      <VStack w='full'>
        {_.map(DBT.Demo2Stage, s => (
          <VStack key={s} w='full' align={'start'}>
            <Divider />
            <SubPrompt
              title={s}
              sp={_.get(conf, ['prompts', s])}
              onChange={sp => onSubPromptChange(sp, s)}
            />
          </VStack>
        ))}
      </VStack>
    )
  }

  return (
    <VStack w='full' h='full' bg='white' overflow={'hidden'}>
      <NavBar title={'Demo 2'} actions={actions} />
      <VStack w='full' overflow={'auto'} p={{ base: 2, lg: 6 }}>
        <VStack w='full' spacing={10}>
          <VoiceSelect value={conf?.voiceId} onSelect={onVoiceChange} />
          {/* <SubPrompt
            title={'Fallback prompt'}
            sp={{
              prompt: _.get(conf, 'prompt', ''),
              jsonScheme: conf?.jsonScheme || ''
            }}
            onChange={sp =>
              onChange({ prompt: sp.prompt, jsonScheme: sp.jsonScheme })
            }
          /> */}
          {renderPrompts()}
          {/* <CannedResponse
            translations={conf?.cannedResponseTranslates || {}}
            onChangeTranslations={v =>
              onChange({ cannedResponseTranslates: v })
            }
            value={conf?.cannedResponse || {}}
            onChange={onChangeCannedResponse}
          /> */}
          {renderInitialPhrase()}
          {/* {renderFinalPhrase()} */}
          {renderTemperatureInput()}
          <AiPlatformSelect
            value={conf?.aiPlatform}
            onChange={onChangeAiPlatform}
          />
          <AIModels value={conf?.models} onChange={onChangeModels} />
          <InterruptionMode
            value={conf?.interruptionMode || DBT.INTERRUPT.DISABLED}
            onChange={onChangeInterruptionMode}
          />
          <TranscriberSelect
            value={conf?.transcriber || DBT.TRANSCRIBER.DEEPGRAM}
            onChange={onChageTranscriber}
          />
        </VStack>
      </VStack>
    </VStack>
  )
}

export default VegasDemo
