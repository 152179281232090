import { Accordion, Button, HStack, useToast, VStack } from '@chakra-ui/react'
import NavBar from 'components/NavBar'
import { useParams } from 'react-router'
import _ from 'lodash'
import { DBT } from 'types/internal'
import { generateId } from 'controllers/db'
import { useEffect, useMemo, useRef, useState } from 'react'
import {
  dbDeleteTest,
  dbRunTest,
  dbSaveTest,
  dbSubscribeToTest
} from 'controllers/tests'
import { Unsubscribe } from 'firebase/firestore'
import Test from 'pages/tests/Test'

const Tests = () => {
  const { testCase } = useParams() as { testCase: string }
  const [tests, setTests] = useState<DBT.Test[]>([])
  const unsubscribeRef = useRef<Unsubscribe | null>(null)
  const [processingTestId, setProcessingTestId] = useState<string | null>(null)
  const [selectedTests, setSelectedTests] = useState<string[]>([])
  const toast = useToast()

  useEffect(() => {
    if (unsubscribeRef.current) {
      unsubscribeRef.current()
    }
    setTests([])
    unsubscribeRef.current = dbSubscribeToTest(testCase, tests => {
      setTests(tests)
    })
    return () => {
      if (unsubscribeRef.current) {
        unsubscribeRef.current()
      }
    }
  }, [testCase])

  const selectedTestsDict = useMemo(() => {
    return _.keyBy(selectedTests)
  }, [selectedTests])

  const runTest = async (testId: string) => {
    try {
      setProcessingTestId(testId)
      const res = await dbRunTest(testCase, testId)
      const success = _.get(res, 'success', false)
      if (!success) {
        toast({
          duration: 3000,
          isClosable: true,
          title: 'Error',
          description: _.get(res, 'message', ''),
          status: 'error'
        })
      }
      setProcessingTestId(null)
    } catch (e) {
      console.error(e)
      setProcessingTestId(null)
    }
  }

  const addTest = () => {
    const t: DBT.Test = {
      id: generateId(),
      createdAt: _.now(),
      title: 'new test',
      metadata: {},
      userInfo: {},
      conversation: [],
      validation: ''
    }
    dbSaveTest(testCase, t)
  }

  const duplicateTest = (testId: string) => {
    const t = _.find(tests, t => t.id === testId)
    if (t) {
      const newT = {
        id: generateId(),
        createdAt: _.now(),
        title: t.title + ' (copy)',
        metadata: t.metadata,
        userInfo: t.userInfo,
        conversation: t.conversation,
        validation: t.validation
      }
      dbSaveTest(testCase, newT)
    }
  }

  const runSelectedTests = async () => {
    for (const t of tests) {
      if (_.has(selectedTestsDict, t.id)) {
        await runTest(t.id)
      }
    }
  }

  const selectAll = () => {
    setSelectedTests(selected => {
      if (_.isEmpty(selected)) {
        return _.map(tests, t => t.id)
      } else {
        return []
      }
    })
  }

  const actions = (
    <HStack>
      <Button
        variant={'outline'}
        colorScheme='white'
        size='sm'
        onClick={runSelectedTests}
        isDisabled={_.isEmpty(selectedTests) || processingTestId !== null}
      >
        Run selected test
      </Button>
      <Button
        variant={'outline'}
        colorScheme='white'
        size='sm'
        onClick={addTest}
      >
        Add test
      </Button>
    </HStack>
  )

  const deleteTest = (testId: string) => {
    dbDeleteTest(testCase, testId)
  }

  const toggleSelect = (testId: string) => {
    setSelectedTests(selected => {
      const isSelected = _.indexOf(selected, testId) >= 0
      if (isSelected) {
        return _.filter(selected, tId => tId !== testId)
      } else {
        return [testId, ...selected]
      }
    })
  }

  const renderTests = () => {
    return _.map(tests, t => {
      return (
        <Test
          t={t}
          key={t.id}
          testCase={testCase}
          isProcessing={processingTestId === t.id}
          onRun={() => runTest(t.id)}
          onDelete={() => deleteTest(t.id)}
          isSelected={_.has(selectedTestsDict, t.id)}
          toggleSelect={() => toggleSelect(t.id)}
          onDuplicate={() => duplicateTest(t.id)}
        />
      )
    })
  }

  return (
    <VStack w='full' h='full' bg='white' overflow={'hidden'}>
      <NavBar title={`Tests ${_.startCase(testCase)}`} actions={actions} />
      <VStack w='full' overflow={'auto'} p={6} spacing={6} align={'start'}>
        <Button
          variant={'link'}
          colorScheme='zinc'
          size='sm'
          onClick={selectAll}
        >
          {_.isEmpty(selectedTests) ? 'select all' : 'unselect all'}
        </Button>
        {renderTests()}
      </VStack>
    </VStack>
  )
}

export default Tests
