import React, { useRef, useState } from 'react'
import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Input,
  VStack,
  useDisclosure
} from '@chakra-ui/react'

interface CreateTemplateButtonProps {
  onCreate: (name: string) => void
}

const CreateTemplateButton: React.FC<CreateTemplateButtonProps> = ({
  onCreate
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [name, setName] = useState('')
  const firstFieldRef = useRef(null)

  const handleCreate = () => {
    if (name.trim()) {
      onCreate(name)
      setName('')
      onClose()
    }
  }

  return (
    <Popover
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      initialFocusRef={firstFieldRef}
    >
      <PopoverTrigger>
        <Button variant={'outline'} colorScheme='white' size='sm'>
          Create template
        </Button>
      </PopoverTrigger>
      <PopoverContent p={4}>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader color='zinc.800'>New Template</PopoverHeader>
        <PopoverBody>
          <VStack spacing={4} pt={4}>
            <Input
              color='zinc.800'
              placeholder='Template Name'
              value={name}
              onChange={e => setName(e.target.value)}
              ref={firstFieldRef}
            />
            <Button variant='primary' onClick={handleCreate}>
              Create
            </Button>
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

export default CreateTemplateButton
