import {
  Box,
  useColorModeValue,
  Drawer,
  DrawerContent,
  useDisclosure,
  Show,
  IconButton
} from '@chakra-ui/react'
import SidebarContent from 'components/SideBarContent'
import { HamburgerIcon } from '@chakra-ui/icons'

export default function SimpleSidebar () {
  const { isOpen, onClose, onOpen } = useDisclosure()
  return (
    <Box minH='100vh' bg={useColorModeValue('zinc.100', 'zinc.900')} w='full'>
      <Show above='lg'>
        <Box h='full' w='full'>
          <SidebarContent onClose={() => null} />
        </Box>
      </Show>
      <Show below='lg'>
        <Drawer
          isOpen={isOpen}
          placement='left'
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size='full'
        >
          <DrawerContent>
            <SidebarContent onClose={onClose} />
          </DrawerContent>
        </Drawer>
        <Box w={4} position={'fixed'} left={2} top={1.5}>
          <IconButton
            aria-label='menu'
            variant={'unstyled'}
            color='white'
            icon={<HamburgerIcon w={5} h={5} />}
            onClick={onOpen}
          />
        </Box>
      </Show>
    </Box>
  )
}
