import {
  Button,
  HStack,
  Image,
  Text,
  useToast,
  VStack,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay
} from '@chakra-ui/react'
import { dbDeleteVegasUser, dbValidateEmail } from 'controllers/vegas'
import { FC, useState, useRef } from 'react'
import { DBT } from 'types/internal'
import _ from 'lodash'
import { DeleteIcon } from '@chakra-ui/icons'

type Props = {
  user: DBT.VegasUserT
  onUserDeleted: () => void
}

const UserProfile: FC<Props> = ({ user, onUserDeleted }) => {
  const [loading, setLoading] = useState(false)
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef<HTMLButtonElement>(null)

  const onDelete = async () => {
    try {
      setLoading(true)
      const res = await dbDeleteVegasUser(user.id)
      if (res && _.get(res, 'success', false)) {
        onUserDeleted()
      }
      setLoading(false)
    } catch (e) {
      console.log('delete user error', e)
      setLoading(false)
    }
  }

  const onValidateClick = async () => {
    try {
      setLoading(true)
      const res = await dbValidateEmail(_.trim(user.email))
      console.log('res', res)
      const isValid = _.get(res, 'isValid', false)
      const verdict = _.get(res, 'details.result.verdict')
      if (_.get(res, 'error', false)) {
        toast({
          title: `Validation Failed, Verdict: ${verdict}`,
          description: `${_.get(
            res,
            'message',
            'An error occurred while validating the email.'
          )} ${JSON.stringify(_.get(res, 'details.result', {}))}`,
          status: 'error',
          duration: 5000,
          isClosable: true
        })
      } else if (isValid) {
        toast({
          title: `Validation Successful, Verdict: ${verdict}`,
          description: 'The email address is valid.',
          status: 'success',
          duration: 5000,
          isClosable: true
        })
      } else {
        toast({
          title: `Validation Failed, Verdict: ${verdict}`,
          description: `${JSON.stringify(_.get(res, 'details.result', {}))}`,
          status: 'error',
          duration: 5000,
          isClosable: true
        })
      }
    } catch (e) {
      console.error(e)
      toast({
        title: 'Error',
        description: 'Failed to validate the email address.',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <HStack w='full' spacing={6} align={'start'}>
      {user.photoUrl && (
        <Image
          boxSize='100px'
          objectFit='cover'
          src={user.photoUrl}
          alt={user.name}
        />
      )}
      <VStack align={'start'} spacing={1}>
        <Text color={'zinc.800'} fontSize={'md'} fontWeight={'semibold'}>
          {user.name}
        </Text>
        <Text color={'zinc.600'} fontSize={'xs'} fontWeight={'normal'}>
          {user.id}
        </Text>
        <Text color={'zinc.600'} fontSize={'xs'} fontWeight={'normal'}>
          {user.jobTitle} {user.company}
        </Text>
        <Text color={'zinc.600'} fontSize={'xs'} fontWeight={'normal'}>
          {user.city} {user.country}
        </Text>
      </VStack>
      <VStack align='start'>
        <Button
          size='xs'
          variant={'primary'}
          isLoading={loading}
          onClick={onValidateClick}
          isDisabled={!user.email}
        >
          Validate email
        </Button>
        <Button
          size='xs'
          colorScheme='red'
          onClick={onOpen}
          isLoading={loading}
          leftIcon={<DeleteIcon />}
        >
          Delete
        </Button>
        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                Delete User
              </AlertDialogHeader>
              <AlertDialogBody>
                Are you sure you want to delete this user? This action cannot be
                undone.
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  colorScheme='red'
                  onClick={() => {
                    onDelete()
                    onClose()
                  }}
                  ml={3}
                >
                  Delete
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </VStack>
    </HStack>
  )
}

export default UserProfile
