import { FC, useMemo } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import _ from 'lodash'
import LoadingPage from 'pages/LoadingPage'
import { useSelector } from 'model/hooks'
import SignIn from 'pages/SignIn'
import NavWrapper from 'navigation/NavWrapper'
import PageLayout from 'navigation/PageLayout'
import Clients from 'pages/Clients'
import Client from 'pages/Client'
import TwoFactorLogs from 'pages/TwoFactorLogs'
import FlexibleApp from 'pages/settings/FlexibleApp'
import TwoFactorDemo from 'pages/settings/TwoFactorDemo'
import Interrupt from 'pages/settings/Interrupt'
import Langs from 'pages/Langs'
import AIAnalysis from 'pages/AIAnalysis'
import Translater from 'pages/Translater'
import TwoFactorLocalization from 'pages/TwoFactorLocalization'
import Demo2 from 'pages/Demo2'
import Users from 'pages/Users'
import Liveness from 'pages/Liveness'
import LivenessLite from 'pages/LivenessLite'
import Module from 'pages/Module'
import Tests from 'pages/Tests'

const AppRouter: FC = () => {
  const authData = useSelector(state => state.authData)

  const privateRoutes = useMemo(() => {
    if (_.isNil(authData)) {
      return <Route path='*' element={<LoadingPage />} />
    } else {
      return (
        <Route path='/' element={<PageLayout />}>
          {/* <Route path='/case/:caseId' element={<Case />} />
          <Route path='/case' element={<Cases />} /> */}
          <Route path='/logs/:interactionId' element={<TwoFactorLogs />} />
          <Route path='/clients/:clientId' element={<Client />} />
          <Route path='/clients' element={<Clients />} />
          <Route path='/interrupt' element={<Interrupt />} />
          <Route path='/flex' element={<FlexibleApp />} />
          <Route path='/two_factor' element={<TwoFactorDemo />} />
          <Route path='/users/:userId' element={<Users />} />
          <Route path='/users' element={<Users />} />
          <Route path='/demo2' element={<Demo2 />} />
          <Route path='/liveness' element={<Liveness />} />
          <Route path='/liveness_lite' element={<LivenessLite />} />
          <Route path='/tf_localization' element={<TwoFactorLocalization />} />
          <Route path='/test'>
            <Route path=':testCase' element={<Tests />} />
          </Route>
          <Route path='/analysis' element={<AIAnalysis />} />
          <Route path='/translater' element={<Translater />} />
          <Route path='/langs' element={<Langs />} />
          <Route path='/module'>
            <Route path=':moduleType' element={<Module />} />
          </Route>
          <Route path='/' element={<Demo2 />} />
          <Route path='*' element={<Clients />} />
        </Route>
      )
    }
  }, [authData])

  const publicRoutes = useMemo(() => {
    if (!_.isNil(authData)) {
      return <Route path='*' element={<LoadingPage />} />
    } else {
      return <Route path='/signin' element={<SignIn />} />
    }
  }, [authData])

  return (
    <BrowserRouter>
      <NavWrapper>
        <Routes>
          {privateRoutes}
          {publicRoutes}
        </Routes>
      </NavWrapper>
    </BrowserRouter>
  )
}

export default AppRouter
