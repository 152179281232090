import { db } from 'controllers/db'
import _ from 'lodash'
import { DBT } from 'types/internal'
import {
  collection,
  doc,
  onSnapshot,
  setDoc,
  updateDoc
} from 'firebase/firestore'
import { ModuleType } from '@facesignai/api'

export const dbSubscribeToModule = (
  moduleType: ModuleType,
  callback: (conf: DBT.AIPromptConfT) => void
) => {
  try {
    const ref = doc(collection(db, 'modules'), moduleType)
    const unsubscribe = onSnapshot(
      ref,
      sn => {
        const flexConf = sn.data() as DBT.AIPromptConfT | undefined
        if (flexConf) {
          callback(flexConf)
        }
      },
      err => {
        console.error(`dbSubscribeToModule error: ${err.message}`)
      }
    )
    return unsubscribe
  } catch (e) {
    console.error('dbSubscribeToModule error', e)
    return null
  }
}

export const dbUpdateModule = async (
  moduleType: ModuleType,
  conf: Partial<DBT.AIPromptConfT>
) => {
  try {
    const ref = doc(collection(db, 'modules'), moduleType)
    await setDoc(ref, conf, { merge: true })
    console.log(`Module ${moduleType} updated successfully.`)
  } catch (e) {
    console.error(`Error updating module ${moduleType}:`, e)
  }
}
