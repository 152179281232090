import { FC, useState } from 'react'
import { Button, HStack, Text, VStack } from '@chakra-ui/react'
import Label from 'components/Label'
import SimpleTextarea from 'shared/components/SimpleTextarea'
import { DBT } from 'types/internal'
import _ from 'lodash'
import JsonSchemeInput from 'components/JsonSchemeInput'
import { ChevronUpIcon, ChevronDownIcon } from '@chakra-ui/icons'

type Props = {
  title: DBT.Demo2Stage | string
  sp?: DBT.SubPromptT
  onChange: (sp: DBT.SubPromptT) => void
}

const defaultSp: DBT.SubPromptT = {
  prompt: '',
  jsonScheme: `
    {
  "$schema": "http://json-schema.org/draft-07/schema#",
  "type": "object",
  "properties": {
    "reply": {
      "type": "string"
    },
    "endSession": {
       "type": "boolean"
    }
  },
  "required": ["reply", "endSession"],
  "additionalProperties": false
}
  `
}

const SubPrompt: FC<Props> = ({ sp, onChange, title }) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleCollapsible = () => setIsOpen(!isOpen)

  const onChangePrompt = (v: string) => {
    const newSp = {
      ...defaultSp,
      ...(sp || {}),
      prompt: v
    }
    onChange(newSp)
  }

  const onJsonSchemeChange = (v: string) => {
    const newSp = {
      ...defaultSp,
      ...(sp || {}),
      jsonScheme: v
    }
    onChange(newSp)
  }

  const renderContent = () => {
    if (isOpen) {
      return (
        <>
          <VStack w='full' align={'flex-start'} spacing={2}>
            <Label>Prompt</Label>
            <SimpleTextarea
              placeholder='Enter prompt here'
              value={_.get(sp, 'prompt', '')}
              onChange={onChangePrompt}
            />
          </VStack>
          <JsonSchemeInput
            value={sp?.jsonScheme || defaultSp.jsonScheme}
            onChange={onJsonSchemeChange}
          />
        </>
      )
    }
  }

  return (
    <VStack w='full' align={'start'}>
      <HStack>
        <Text color='zinc.800' fontWeight={'bold'}>
          {_.startCase(title)}
        </Text>
        <Button
          onClick={toggleCollapsible}
          size='sm'
          variant={'ghost'}
          // visibility={isOpen ? 'visible' : 'hidden'}
          // _groupHover={{ visibility: 'visible' }}
        >
          {isOpen ? <ChevronUpIcon ml={1} /> : <ChevronDownIcon ml={1} />}
        </Button>
      </HStack>
      {renderContent()}
    </VStack>
  )
}

export default SubPrompt
