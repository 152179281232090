import { FC, useMemo, useState } from 'react'
import _ from 'lodash'
import {
  Button,
  HStack,
  IconButton,
  Input,
  VStack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Box,
  AccordionIcon
} from '@chakra-ui/react'
import { DeleteIcon } from '@chakra-ui/icons'

type Props = {
  user: Record<string, any>
  handleEdit: (key: string, value: string) => void
  handleDelete: (key: string) => void
  handleAdd: (key: string, value: string) => void
}

const UserParamsList: FC<Props> = ({
  user,
  handleEdit,
  handleDelete,
  handleAdd
}) => {
  const [newKey, setNewKey] = useState('')
  const [newValue, setNewValue] = useState('')

  const sortedKeys = useMemo(() => {
    return _.keys(user).sort()
  }, [user])

  const onAddClick = () => {
    if (
      newKey &&
      newValue &&
      newKey !== 'id' &&
      newKey !== 'photoUrl' &&
      newKey !== 'photos' &&
      newKey !== 'faces'
    ) {
      handleAdd(newKey, newValue)
      setNewKey('')
      setNewValue('')
    }
  }

  const renderAddUserParam = () => {
    if (user) {
      return (
        <HStack w='full'>
          <Input
            placeholder='New key'
            value={newKey}
            onChange={e => setNewKey(e.target.value)}
            size='sm'
            flex={1}
            flexShrink={0}
          />
          <Input
            placeholder='New value'
            value={newValue}
            onChange={e => setNewValue(e.target.value)}
            size='sm'
            flex={2}
            flexShrink={0}
          />
          <Button size='sm' onClick={onAddClick} variant={'primary'}>
            Add
          </Button>
        </HStack>
      )
    }
  }

  return (
    <Accordion w={'full'} allowToggle>
      <AccordionItem>
        <AccordionButton>
          {' '}
          <Box as='span' flex='1' textAlign='left'>
            Params list
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel pb={4}>
          <VStack w='full' align={'start'}>
            {_.map(sortedKeys, key => {
              const val = user[key]
              return (
                <VStack key={key} align='stretch' w='full'>
                  <HStack align={'start'}>
                    <Input
                      value={key}
                      isReadOnly
                      size='sm'
                      flex={1}
                      flexShrink={0}
                      isDisabled={key === 'id'}
                    />
                    <Input
                      value={val}
                      size='sm'
                      onChange={e => handleEdit(key, e.target.value)}
                      flex={2}
                      isDisabled={key === 'id'}
                    />
                    <IconButton
                      w={{ base: 4, lg: '53px' }}
                      aria-label='Delete'
                      variant={'ghost'}
                      icon={<DeleteIcon />}
                      onClick={() => handleDelete(key)}
                      size={{ base: 'xs', lg: 'sm' }}
                      isDisabled={key === 'id'}
                    />
                  </HStack>
                </VStack>
              )
            })}
            {renderAddUserParam()}
          </VStack>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}

export default UserParamsList
