import { useLayoutEffect, useRef, useState } from 'react'
import {
  Textarea,
  Flex,
  VStack,
  Button,
  HStack,
  Select,
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  useToast,
  Text,
  Checkbox,
  Input
} from '@chakra-ui/react'
import { callChat } from 'controllers/chat'
import _ from 'lodash'

type M = {
  role: 'user' | 'assistant'
  message: string
}

const ChatPanel = () => {
  const [messages, setMessages] = useState<M[]>([])
  const [value, setValue] = useState('')
  const [loading, setLoading] = useState(false)
  const [model, setModel] = useState('gpt-4o')
  const [isJson, setIsJson] = useState(false)
  const [temperature, setTemperature] = useState(1)
  const [maxTokens, setMaxTokens] = useState(1000)
  const toast = useToast()
  const mConteinerRef = useRef<HTMLDivElement>(null)
  const [images, setImages] = useState('')

  const scrollToBottom = () => {
    const elt = mConteinerRef.current
    if (elt) {
      elt.scrollTo(0, elt.scrollHeight)
    }
  }

  useLayoutEffect(() => {
    scrollToBottom()
  }, [messages])

  const apply = async () => {
    if (value !== '') {
      setLoading(true)
      setMessages(messages => [...messages, { role: 'user', message: value }])
      setValue('')
      const imagesAr = _.split(images, ',')
      const res = await callChat(
        value,
        model,
        temperature,
        maxTokens,
        isJson,
        imagesAr
      )
      setLoading(false)
      if (res) {
        const error = _.get(res, 'error')
        if (error) {
          toast({
            duration: 3000,
            isClosable: true,
            title: 'Error',
            description: error,
            status: 'error'
          })
        }
        const m = _.get(res, 'message')
        if (m) {
          setMessages(messages => [
            ...messages,
            { role: 'assistant', message: m }
          ])
        }
      }
    }
  }

  const renderMessage = (m: M, i: number) => {
    return (
      <VStack
        key={i}
        align={m.role === 'user' ? 'flex-end' : 'flex-start'}
        w='full'
      >
        <Text
          fontSize={'sm'}
          color='gray.600'
          fontWeight={m.role === 'user' ? '400' : '600'}
          textAlign={m.role === 'user' ? 'end' : 'start'}
        >
          {m.role === 'user' ? <i>{m.message}</i> : m.message}
        </Text>
      </VStack>
    )
  }

  const renderMessages = () => {
    return (
      <VStack
        h='full'
        w='full'
        p={4}
        spacing={6}
        overflowY={'auto'}
        ref={mConteinerRef}
      >
        {_.map(messages, renderMessage)}
      </VStack>
    )
  }

  const renderInput = () => {
    return (
      <HStack w='full' align='flex-end' p={4}>
        <Textarea
          minH={'40'}
          value={value}
          size='sm'
          onChange={e => setValue(e.target.value)}
          // onKeyDown={event => {
          //   if (event.key === 'Enter') {
          //     event.preventDefault()
          //     apply()
          //   }
          // }}
        />
        <Button
          variant='solid'
          colorScheme='teal'
          onClick={apply}
          isLoading={loading}
          size='sm'
        >
          Send
        </Button>
      </HStack>
    )
  }

  const renderImagesInput = () => {
    return (
      <FormControl>
        <FormLabel fontSize={'xs'} mb={0}>
          Comma separated images urls
        </FormLabel>
        <Input
          size='sm'
          value={images}
          onChange={e => setImages(e.target.value)}
        />
      </FormControl>
    )
  }

  const renderSettings = () => {
    return (
      <VStack p={4} bg='gray.100'>
        <HStack>
          <FormControl>
            <FormLabel fontSize={'xs'} mb={0}>
              Model
            </FormLabel>
            <Select
              value={model}
              onChange={e => setModel(e.target.value)}
              size='xs'
            >
              <option value='gpt-4o'>gpt-4o</option>
              <option value='gpt-4-turbo'>gpt-4-turbo</option>
              <option value='gpt-3.5-turbo'>gpt-3.5-turbo</option>
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel fontSize={'xs'} mb={0}>
              Tempterature
            </FormLabel>
            <NumberInput
              value={temperature}
              precision={2}
              step={0.05}
              size='xs'
              onChange={(s, n) => setTemperature(n)}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </FormControl>

          <FormControl>
            <FormLabel fontSize={'xs'} mb={0}>
              Max tokens
            </FormLabel>
            <NumberInput
              value={maxTokens}
              precision={0}
              step={1}
              size='xs'
              onChange={(s, n) => setMaxTokens(n)}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </FormControl>
          <FormControl>
            <FormLabel fontSize={'xs'} mb={0}>
              JSON
            </FormLabel>
            <Checkbox
              isChecked={isJson}
              onChange={e => setIsJson(e.target.checked)}
            />
          </FormControl>
        </HStack>
        {renderImagesInput()}
      </VStack>
    )
  }

  return (
    <Flex direction='column' h='full' w='full' borderLeft={1}>
      {renderSettings()}
      {renderMessages()}
      {renderInput()}
    </Flex>
  )
}

export default ChatPanel
