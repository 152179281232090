import {
  Box,
  BoxProps,
  VStack,
  Divider,
  Text,
  IconButton,
  Show
} from '@chakra-ui/react'
import { dbSignOut } from 'controllers/auth'
import { useNavigate, useLocation } from 'react-router'
import _ from 'lodash'
import NavItem from 'components/NavItem'
import { ArrowLeftIcon } from '@chakra-ui/icons'

interface LinkItemProps {
  name?: string
  path?: string
  heading?: string
}

// | EmailVerification
// | SmsVerification
// | IdentityVerification
// | DocumentAuthentication
// | AgeEstimation
// | ProofOfIntent
// | KnowledgeVerify

const LinkItems: LinkItemProps[][] = [
  [
    { heading: 'Prompts' },
    { name: 'Demo 2', path: 'demo2' },
    { name: 'Two Factor Demo', path: 'two_factor' },
    { name: 'Flexible App', path: 'flex' },
    { name: 'Liveness Detection', path: 'liveness' },
    { name: 'Liveness Detection (Lite)', path: 'liveness_lite' },
    { name: 'Interrupt', path: 'interrupt' },
    { name: 'AI Analysis', path: 'analysis' }
  ],
  [
    { heading: 'Localization' },
    { name: 'Two Factor Localization', path: 'tf_localization' },
    { name: 'Translater', path: 'translater' },
    { name: 'Langs', path: 'langs' }
  ],
  [
    { heading: 'Modules' },
    { name: 'Email Verification', path: 'module/emailVerification' },
    { name: 'SMS Verification', path: 'module/smsVerification' },
    {
      name: 'Identity Verification',
      path: 'module/identityVerification'
    },
    {
      name: 'Document Authentication',
      path: 'module/documentAuthentication'
    },
    {
      name: 'Age Esimation',
      path: 'module/ageEstimation'
    },
    {
      name: 'Proof of Intent',
      path: 'module/ageEstimation'
    },
    {
      name: 'Knowledge Verify',
      path: 'module/knowledgeVerify'
    }
  ],
  [
    { heading: 'Tests' },
    { name: 'Demo 2', path: 'test/demo2' },
    { name: 'Liveness', path: 'test/liveness' }
  ],
  [
    { name: 'Clients', path: 'clients' },
    { name: 'Users', path: 'users' }
  ]
]

interface SidebarProps extends BoxProps {
  onClose: () => void
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  const navigate = useNavigate()
  const location = useLocation()

  const firstPath = _.get(location.pathname.split('/'), 1)

  const onItemClick = (path?: string) => {
    if (path) {
      navigate(path)
      onClose()
    }
  }

  const renderItem = (item: LinkItemProps) => {
    if (_.has(item, 'heading')) {
      return (
        <Text
          color='zinc.500'
          key={item.heading}
          pl={4}
          fontWeight={'semibold'}
          pb={2}
        >
          {item.heading}
        </Text>
      )
    } else {
      return (
        <NavItem
          key={item.name}
          path={item.path}
          isSelected={firstPath === item.path}
          onClick={() => onItemClick(item.path)}
        >
          <Text fontSize={'sm'} textAlign={'start'}>
            {item.name}
          </Text>
        </NavItem>
      )
    }
  }

  return (
    <VStack
      bg={'zinc.900'}
      borderRight='1px'
      borderRightColor={'zinc.700'}
      w={{ base: 'full', md: 'full' }}
      // pos='fixed'
      h='full'
      pt={4}
      overflow={'hidden'}
      align={'flex-start'}
      {...rest}
    >
      <Show below='lg'>
        <IconButton
          variant={'unstyled'}
          color='white'
          aria-label='close_menu'
          icon={<ArrowLeftIcon w={4} h={4} />}
          onClick={onClose}
        />
      </Show>
      <VStack
        h='full'
        w='full'
        align={'flex-start'}
        divider={<Divider />}
        overflowY={'auto'}
      >
        {LinkItems.map((links, index) => (
          <VStack key={index} w='full' align={'start'} spacing={0}>
            {_.map(links, renderItem)}
          </VStack>
        ))}
      </VStack>

      <Box pb={2} pr={24}>
        <NavItem onClick={() => dbSignOut(navigate)}>Sign Out</NavItem>
      </Box>
    </VStack>
  )
}

export default SidebarContent
