import { FC } from 'react'
import { Radio, RadioGroup, Stack, VStack } from '@chakra-ui/react'
import { DBT } from 'types/internal'
import Label from 'components/Label'

interface Props {
  value?: DBT.AIPlatform
  onChange: (value: DBT.AIPlatform) => void
}

const AiPlatformSelect: FC<Props> = ({
  value = DBT.AIPlatform.OPENAI,
  onChange
}) => {
  return (
    <VStack align='start' spacing={4} w='full'>
      <Label>AI Platform</Label>
      <RadioGroup onChange={onChange} value={value} size='sm'>
        <Stack direction='row'>
          <Radio value={DBT.AIPlatform.OPENAI}>OpenAI</Radio>
          <Radio value={DBT.AIPlatform.GROQ}>Groq</Radio>
        </Stack>
      </RadioGroup>
    </VStack>
  )
}

export default AiPlatformSelect
