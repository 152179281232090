import { FC, useRef } from 'react'
import {
  HStack,
  Flex,
  Box,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  Button,
  Image,
  Show
} from '@chakra-ui/react'
import { Outlet } from 'react-router-dom'
import SideBar from 'components/SimpleSidebar'
import ChatPanel from 'components/ChatPanel'
import chatgptIcon from 'assets/chatgpt_logo.svg'
import DevIndicator from 'shared/components/DevIndicator'
import conf from 'src/config'

const PageLayout: FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = useRef<HTMLButtonElement>(null)

  const renderChagptDrawer = () => {
    return (
      <>
        <Button
          aria-label='chatgpt'
          ref={btnRef}
          onClick={onOpen}
          position={'fixed'}
          size='sm'
          w='4'
          p={0}
          left={{ base: 4, lg: 48 }}
          bottom={{ base: 16, lg: 2 }}
          variant={'ghost'}
        >
          <Image src={chatgptIcon} w='full' h='full' />
        </Button>
        <Drawer
          isOpen={isOpen}
          placement='left'
          onClose={onClose}
          finalFocusRef={btnRef}
          size='lg'
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />

            <DrawerBody w='full' p={0}>
              <ChatPanel />
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </>
    )
  }

  return (
    <HStack align='flex-start' spacing={0} w='full' h='full' overflow='hidden'>
      <Flex h='full' w={{ lg: '60', base: 'auto' }} flexShrink={0}>
        <SideBar />
      </Flex>
      <Flex h='full' w='full' bg='white' overflowY='auto' p={0}>
        <Outlet />
      </Flex>
      {renderChagptDrawer()}
      {conf.isDev && <DevIndicator />}
    </HStack>
  )
}

export default PageLayout
