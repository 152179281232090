import { FC } from 'react'
import { Flex, HStack, Text, VStack } from '@chakra-ui/react'
import { DBT } from 'types/internal'
import Label from 'components/Label'
import _ from 'lodash'
import SimpleInput from 'shared/components/SimpleInput'

interface Props {
  value?: Partial<Record<DBT.AIPlatform, string>>
  onChange: (value: Record<DBT.AIPlatform, string>) => void
}

const AIModels: FC<Props> = ({ value = {}, onChange }) => {
  const updateModel = (v: DBT.AIPlatform, model: string) => {
    if (model && !_.isEmpty(_.trim(model))) {
      onChange({ ...value, [v]: model } as Record<DBT.AIPlatform, string>)
    } else {
      const newValue = { ...value }
      delete newValue[v]
      onChange(newValue as Record<DBT.AIPlatform, string>)
    }
  }

  const renderRow = (v: DBT.AIPlatform, k: string) => {
    return (
      <HStack>
        <Flex w='28'>
          <Text fontSize={'sm'} color='zinc.600'>
            {k}:
          </Text>
        </Flex>
        <SimpleInput
          size='sm'
          value={_.get(value, v, '')}
          onChange={(model: string) => updateModel(v, model)}
        />
      </HStack>
    )
  }

  return (
    <VStack align='start' spacing={4} w='full'>
      <Label>Models</Label>
      {_.map(DBT.AIPlatform, renderRow)}
    </VStack>
  )
}

export default AIModels
