import { FC, useRef, useState, useEffect } from 'react'
import { VStack, Text, HStack, Textarea, TextareaProps } from '@chakra-ui/react'
import ResizeTextarea from 'react-textarea-autosize'

type Props = Omit<TextareaProps, 'onChange'> & {
  onChange: (v: string) => void
  clearOnApply?: boolean
  label?: string
}

const SimpleInput: FC<Props> = ({
  onChange,
  value,
  clearOnApply,
  label,
  ...rest
}) => {
  const ref = useRef<HTMLTextAreaElement>(null)
  const [v, setV] = useState<string>(value ? value.toString() : '')

  useEffect(() => {
    setV(value ? value.toString() : '')
  }, [value])

  const apply = () => {
    // console.log('onBlur, apply', v)
    onChange(v)
    if (clearOnApply) {
      setV('')
    }
  }

  return (
    <HStack w='full' align={'flex-end'}>
      <VStack w={'full'} spacing={1} align={'flex-start'}>
        {label && (
          <Text fontSize='sm' color='black.900' fontWeight={'semibold'}>
            {label}
          </Text>
        )}
        <Textarea
          ref={ref}
          placeholder='Enter prompt here'
          value={v}
          onChange={e => setV(e.target.value)}
          size='sm'
          fontSize={'sm'}
          minH='unset'
          overflow='hidden'
          w='100%'
          resize='none'
          onBlur={apply}
          color='black.600'
          minRows={2}
          as={ResizeTextarea}
          {...rest}
        />
      </VStack>
    </HStack>
  )
}

export default SimpleInput
