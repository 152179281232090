import { ReactNode } from 'react'
import { Box, Flex, FlexProps, useColorModeValue } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

interface NavItemProps extends FlexProps {
  children: ReactNode
  path?: string
  isSelected?: boolean
  onClick?: () => void
}

const NavItem = ({
  children,
  path,
  isSelected,
  onClick,
  ...rest
}: NavItemProps) => {
  const navigate = useNavigate()
  const selectedBg = useColorModeValue('zinc.700', 'zinc.700')
  const hoverBg = useColorModeValue('zinc.700', 'zinc.800')

  const handleClick = () => {
    if (onClick) {
      onClick()
    } else if (path) {
      navigate(path)
    }
  }

  return (
    <Box
      as='button'
      onClick={handleClick}
      style={{ textDecoration: 'none' }}
      _focus={{ boxShadow: 'none' }}
      w='full'
      px={2}
    >
      <Flex
        align='center'
        px='2'
        py={1}
        borderRadius='sm'
        role='group'
        cursor='pointer'
        bg={isSelected ? selectedBg : 'transparent'}
        color={'white'}
        fontWeight={isSelected ? 'semibold' : 'normal'}
        _hover={{
          bg: isSelected ? selectedBg : hoverBg
        }}
        {...rest}
      >
        {children}
      </Flex>
    </Box>
  )
}

export default NavItem
