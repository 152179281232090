const conf = {
  backendUrl: 'https://facesign-dev-api.web.app',
  isDev: true,
  apiKey: 'AIzaSyC9Yrb5KsOSLelGbl9ymc-90SsNZCPTkDI',
  authDomain: 'facesignai-dev.firebaseapp.com',
  projectId: 'facesignai-dev',
  storageBucket: 'facesignai-dev.appspot.com',
  messagingSenderId: '511529342332',
  appId: '1:511529342332:web:a48b075c56218f48a240f9',
  algoliaAppId: 'I7HTIQZDPG',
  algoliaSearchKey: '971a5f1a2c6944c1b77fc6ec5e8c2832',
  vegasUsersBucket: 'gs://facesignai-dev-vegas-users'
}

module.exports = conf
